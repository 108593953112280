import { animate, scroll, timeline } from "motion";
import LazyLoad from "vanilla-lazyload";

document.addEventListener("DOMContentLoaded", (event) => {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    use_native: true,
  });

  const sequence = [
    [
      ".wheel.left",
      { rotate: [-90, -30] },
      { at: 0, allowWebkitAcceleration: true },
    ],
    [
      ".wheel.right",
      { rotate: [-45, 0] },
      { at: 0, allowWebkitAcceleration: true },
    ],
    [
      ".line1",
      { translate: [-50, 50] },
      { at: 0, allowWebkitAcceleration: true },
    ],
    [
      ".line2",
      { translate: [50, -50] },
      { at: 0, allowWebkitAcceleration: true },
    ],
    [
      ".line3",
      { translate: [-50, 50] },
      { at: 0, allowWebkitAcceleration: true },
    ],
    [".map", { scale: [1, 1.25] }, { at: 0, allowWebkitAcceleration: true }],
  ];

  scroll(timeline(sequence));
});
